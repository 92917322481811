<template>
	<aside>
		<div class="headline">
			<h2>Schema</h2>
			<unicon
				name="info-circle"
				:fill="
					$v.firstRow.$anyError || $v.lastRow.$anyError ? '#990033' : '#023c40'
				"
				icon-style="line"
				width="21"
				height="21"
			/>
			<div class="schema-info">
				<h3>Mögliche Werte</h3>
				<table>
					<tbody>
						<tr
							v-for="item in JSON.parse($store.getters.getLab.schema)"
							:key="item.id"
						>
							<td>{{ item.key }}</td>
							<td>{{ item.value }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="error">
			<p class="has-error" v-if="$v.firstRow.$anyError || $v.lastRow.$anyError">
				Es gibt einen Fehler im Schema
			</p>
		</div>
		<div class="schema-wrapper">
			<div
				class="schema-input top-row"
				:class="{
					last: firstRow.length - 1 === parseInt(index),
					error: s.$error
				}"
				v-for="(s, index) in $v.firstRow.$each.$iter"
				:key="s.$model.order"
			>
				<input type="text" v-model="s.value.$model" />
				<label>{{ s.$model.name }}</label>
			</div>
			<div
				class="schema-input bottom-row"
				:class="{
					last: parseInt(index) === firstRow.length - 1,
					error: s.$error
				}"
				v-for="(s, index) in $v.lastRow.$each.$iter"
				:key="s.$model.order"
			>
				<label>{{ s.$model.name }}</label>
				<input type="text" v-model="s.value.$model" />
			</div>
		</div>
	</aside>
</template>

<script>
import { helpers } from 'vuelidate/lib/validators';
function isInList(value) {
	return !helpers.req(value) || this.allowedValues.includes(value);
}
export default {
	data() {
		return {
			schema: [
				{ name: 18, order: 0, value: '' },
				{ name: 17, order: 1, value: '' },
				{ name: 16, order: 2, value: '' },
				{ name: 15, order: 3, value: '' },
				{ name: 14, order: 4, value: '' },
				{ name: 13, order: 5, value: '' },
				{ name: 12, order: 6, value: '' },
				{ name: 11, order: 7, value: '' },
				{ name: 21, order: 8, value: '' },
				{ name: 22, order: 9, value: '' },
				{ name: 23, order: 10, value: '' },
				{ name: 24, order: 11, value: '' },
				{ name: 25, order: 12, value: '' },
				{ name: 26, order: 13, value: '' },
				{ name: 27, order: 14, value: '' },
				{ name: 28, order: 15, value: '' },
				{ name: 48, order: 16, value: '' },
				{ name: 47, order: 17, value: '' },
				{ name: 46, order: 18, value: '' },
				{ name: 45, order: 19, value: '' },
				{ name: 44, order: 20, value: '' },
				{ name: 43, order: 21, value: '' },
				{ name: 42, order: 22, value: '' },
				{ name: 41, order: 23, value: '' },
				{ name: 31, order: 24, value: '' },
				{ name: 32, order: 25, value: '' },
				{ name: 33, order: 26, value: '' },
				{ name: 34, order: 27, value: '' },
				{ name: 35, order: 28, value: '' },
				{ name: 36, order: 29, value: '' },
				{ name: 37, order: 30, value: '' },
				{ name: 38, order: 31, value: '' }
			]
		};
	},
	computed: {
		firstRow: function () {
			let d = [...this.schema];
			return d.sort(this.compare).splice(0, 16);
		},
		lastRow: function () {
			let d = [...this.schema];
			return d.sort(this.compare).splice(16, 32);
		},
		allowedValues: function () {
			return JSON.parse(this.$store.getters.getLab.schema).map((a) => a.key);
		}
	},
	validations: {
		firstRow: {
			$each: {
				value: {
					isInList
				}
			}
		},
		lastRow: {
			$each: {
				value: {
					isInList
				}
			}
		}
	},
	methods: {
		compare(a, b) {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		},
		checkForErrors() {
			this.$v.firstRow.$touch();
			this.$v.lastRow.$touch();
			if (!this.$v.$anyError) {
				return this.schema;
			} else {
				return false;
			}
		},
		reset() {
			for (let x of this.schema) {
				x.value = '';
			}
			this.$v.$reset();
		}
	}
};
</script>

<style lang="scss">
aside {
	display: grid;
	grid-template-rows: 4rem 2rem auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	.headline {
		display: flex;
		align-items: center;
		h2 {
			padding: 1rem 1rem 0.5rem 0;
		}
		.unicon {
			padding: 1rem 0 0.5rem 0;
		}
		.unicon:hover ~ .schema-info {
			display: block;
		}
		.schema-info {
			display: none;
			position: absolute;
			z-index: 1000;
			background-color: $background-color;
			box-shadow: $box-shadow-default;
			padding: 1rem;
			top: 0;
			margin-left: 5%;
			margin-top: 7.5%;
			border-radius: 10px;
			td {
				padding: 0 0.5rem;
			}
		}
	}
	.has-error {
		padding: 0.25rem 0;
	}
	.schema-wrapper {
		display: flex;
		flex-wrap: wrap;
		max-width: 100%;
		padding: 0;
		height: min-content;
		.schema-input {
			max-width: calc(100% / 16);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-self: center;
			flex-grow: 0;
			border-width: 0 1px 0 1px;
			input,
			label {
				padding: 0.7rem 0.1rem;
				margin: 0;
				border-radius: 0;
				box-shadow: none;
			}
			&.top-row {
				border-style: solid;
				border-color: $grey;
				label {
					border-top: 0px solid $grey;
				}
				&.last {
					border-width: 0 0 0px 0;
				}
			}
			&.bottom-row {
				border-style: solid;
				border-color: $grey;
				label {
					border-bottom: 0px solid $grey;
				}
				&.last {
					border-width: 0 0 0 0;
				}
			}
			&.error {
				background: rgba($background-nok, 0.5);
			}
		}
	}
}
</style>
